import React, { createContext, useContext, useState, useEffect } from 'react'
import { initializeMapp } from '../services/mapp/initMapp'
import { useConfig } from './ConfigProvider'
import { useTranslation } from 'react-i18next'
import { checkNoTrackingParam } from '../helpers/checkNoTrackingParam';

export const ContentGroupContext = createContext({
    contentGroups: {},
    isContentGroupReady: false,
    isMappScriptReady: false
})

export const useContentGroups = () => useContext(ContentGroupContext)

export const ContentGroupProvider = ({ children }) => {
    const { config, state: configState } = useConfig()

    const [contentGroups, setContentGroups] = useState({
        // Initial state when loading the app...
        1: 'messe-login', // Example fixed value
        2: 'loading',
        3: 'loading',
        4: 'loading',
        5: 'loading',
        6: 'loading',
        7: 'loading',
    })

    const [isContentGroupReady, setIsContentGroupReady] = useState(false)
    const [isMappScriptReady, setIsMappScriptReady] = useState(false)
    const { i18n } = useTranslation()
    
    // Update ContentGroupProvider Context
    useEffect(() => {
        const updateContentGroups = async () => {
            try {
                // Retrieve CG 2
                const language = i18n.resolvedLanguage?.toLocaleLowerCase() || 'not-categorized'

                // Retrieve CG 3-5
                const params = new URLSearchParams(window.location.search)
                const normalizedParams = {}
                for (const [key, value] of params) {
                    normalizedParams[key.toLowerCase()] = value
                }

                // Check for gig_login_hint and parse if present
                if (normalizedParams['gig_login_hint']) {
                    const gigLoginHintParams = JSON.parse(
                        decodeURIComponent(normalizedParams['gig_login_hint'])
                    )
                    for (const [key, value] of Object.entries(gigLoginHintParams)) {
                        normalizedParams[key.toLowerCase()] = value
                    }
                }

                setContentGroups((currentCGs) => ({
                    ...currentCGs,
                    2: language || 'not-categorized',
                    3: normalizedParams['appid'] || 'not-categorized',
                    4: normalizedParams['subid'] || 'not-categorized',
                    5: normalizedParams['event'] || 'not-categorized',
                }))
                setIsContentGroupReady(true)
            } catch (error) {
                if (window?.localStorage?.getItem('debugMode')) console.error('Error initializing content groups:', error)
            }
        }

        updateContentGroups()
    }, [i18n.resolvedLanguage])

    // initialize Mapp    
    useEffect(() => {
        const noTracking = checkNoTrackingParam('notracking');

        if (configState === 'available' && isContentGroupReady && !noTracking) {
            initializeMapp(contentGroups, config, setIsMappScriptReady);
        }
    },[configState, config, isContentGroupReady, contentGroups])

    return (
        <ContentGroupContext.Provider value={{ contentGroups, isContentGroupReady, isMappScriptReady }}>
            {children}
        </ContentGroupContext.Provider>
    )
}
