import './Error.css'
import logo from './MesseLogoSimple.svg'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../providers/ConfigProvider'
import { useClientConfig } from '../../providers/ClientConfigProvider'
import { ContentGroupContext } from '../../providers/ContentGroupProvider'
import { useEffect, useContext } from 'react'

function ErrorPage({ errorCode = null }) {
    const { t } = useTranslation()
    const { config } = useConfig()
    const { clientConfig } = useClientConfig()
    const { isMappScriptReady } = useContext(ContentGroupContext)
    if (window?.localStorage?.getItem('debugMode')) console.log('errorCode:', errorCode)

    useEffect(() => {
        if(isMappScriptReady){
            window.dispatchEvent(new CustomEvent('cdc-screen-loaded', {
                detail: {
                    cg6: 'error',
                    cg7: 'error-page',
                }
            }))
        }
    }, [isMappScriptReady])

    return (
        <div className="content error">
            <img src={logo} className="error-logo" alt="Messe Frankfurt" />
            <h1 className="error-title">{t('ErrorPage.Error_Title')}</h1>
            <p className="error-description">{t('ErrorPage.Error_Text')}</p>
            <a className="back-button" href={clientConfig?.returnUrl ? clientConfig.returnUrl : config.app?.defaultReturnUrl} target="_self">
                {clientConfig?.returnUrl ? t('Return_Button.Website') : t('Return_Button.Default')}
            </a>
        </div>
    )
}

export default ErrorPage