import { useEffect } from 'react'
import LoadingSpinner from '../../components/LoadingSpinner'

function Redirect({ to }) {
    useEffect(() => {
        window.location = to
    }, [to])

    return (
        <div className="content redirect loading-spinner">
            <LoadingSpinner />
        </div>
    )
}

export default Redirect
