import { useEffect } from 'react'
import { useConfig } from '../../providers/ConfigProvider'
import { checkNoTrackingParam } from '../../helpers/checkNoTrackingParam'


function UserCentrics() {
    const { config, state: configState } = useConfig()

    const noTracking = checkNoTrackingParam('notracking');
    
    useEffect(() => {
        // Prevents any further rendering or script loading if noTracking is true
        if (noTracking) {
            return;
        }

        if (
            configState !== 'available' ||
            !config.userCentrics.src ||
            !config.userCentrics.id
        )
            return;

        const scriptId = 'usercentrics-cmp';
        const userCentricsScript = document.getElementById('usercentrics-cmp');

        if (!userCentricsScript) {
            const script = document.createElement('script');
            script.src = config.userCentrics.src;
            script.id = scriptId;
            script.setAttribute('data-settings-id', config.userCentrics.id);
            document
                .getElementById('usercentrics-container')
                ?.appendChild(script);
        }
    }, [configState, config.userCentrics, noTracking]);

    // If noTracking is true, render nothing
    if (noTracking) {
        return null;
    }

    return (
        <div id="usercentrics-container" className="usercentrics" style={{width: '0px', height: '0px', display:'none', visibility: 'hidden'}}>
        </div>
    )
}

export default UserCentrics
