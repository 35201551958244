import CdcScreenSet from "../../components/CdcScreenSet"
import { useCdcContext } from '../../providers/CdcProvider'

function CompleteRegistrationPage() {
    const { user, regToken } = useCdcContext()
    
    return (
        <div className="content">
            <CdcScreenSet
                screenSet="MF-Default-RegistrationLogin"
                startScreen="gigya-complete-registration-screen"
                additionalContext={{verifyLoginResponse: user}}
                regToken={regToken}
                additionalParams={{
                    initialMethod: 'accounts.verifyLogin',
                    initialResponse: user
                }}
            />
        </div>
    )
}

export default CompleteRegistrationPage
