import { createContext, useCallback, useMemo, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfig } from './ConfigProvider'
import { useClientConfig } from './ClientConfigProvider'


export const CdcTokenContext = createContext({})

export const CdcTokenProvider = ({ children, type, token }) => {
    const { config, state: configState } = useConfig()
    const { clientConfig, state: clientConfigState } = useClientConfig()
    
    const initialState = {
        state: null,
        token: null
    }
    
    function reducer(state, action) {
        switch (action.type) {
            case 'cdcTokenProviderUnloaded': {
                return initialState
            }
            case 'getTokenLoading': {
                return {
                    ...state,
                    state: 'loading'
                }
            }
            case 'apiError': {
                return {
                    ...state,
                    state: 'invalid-token'
                }
            }
            case 'tokenInvalid': {
                return {
                    ...state,
                    state: 'invalid-token'
                }
            }
            case 'tokenValid': {
                return {
                    ...state,
                    state: 'valid-token',
                    token: action.payload?.token
                }
            }
            default:
                throw Error(`Unknown action: ${action.type}`)
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { i18n } = useTranslation()

    const handleGetTokenResponse = useCallback(
        (response) => {
            if (response.ok) {
                response.json().then(resJson => {
                    if(resJson.success){
                        dispatch({
                            type: 'tokenValid',
                            payload: resJson
                        })
                    } else {
                        dispatch({
                            type: 'tokenInvalid'
                        })
                    }
                }).catch(err => {
                    dispatch({
                        type: 'apiError'
                    })
                })
            } else {
                dispatch({
                    type: 'apiError'
                })
            }
        },
        [dispatch]
    )

    const context = useMemo(() => ({ ...state, dispatch, handleGetTokenResponse}), [state, handleGetTokenResponse])

    useEffect(() => {
        if (configState !== 'available' || !config?.oauth2?.getTokenEndpoint) return
        if (clientConfigState !== 'available' || !clientConfig?.cdc?.apiKey) return
        if(token && type){
            let tokenType = ''
            switch(type){
                case 'oidc_at':
                    tokenType = 'at'
                    break
                default:
                    tokenType = 'at'
            }
            const query = {
                apikey: clientConfig.cdc.apiKey,
                type: tokenType,
                token: token
            }
            let getQuery = ''
            for (const [key, value] of Object.entries(query)) {
                getQuery += (getQuery === '' ? '?' : '&') + key + '=' + encodeURIComponent(value)
            }
            dispatch({ type: 'getTokenLoading' })
            fetch(config.oauth2.getTokenEndpoint + getQuery)
                .then(res => handleGetTokenResponse(res))
                .catch(e => dispatch({ type: 'apiError' }))
        }
        return () => {
            dispatch({type: 'cdcTokenProviderUnloaded'})
        }
    }, [configState, config?.oauth2, i18n.resolvedLanguage, dispatch, handleGetTokenResponse, clientConfigState, clientConfig?.cdc?.apiKey, token, type])

    return (
        <CdcTokenContext.Provider value={context}>
            {children}
        </CdcTokenContext.Provider>
    )
}

function Loading({ children }) {
    const { state } = useContext(CdcTokenContext)

    if (state !== 'loading') return null

    return <>{children}</>
}

function Valid({ children }) {
    const { state } = useContext(CdcTokenContext)

    if (state !== 'valid-token') return null

    return <>{children}</>
}

function Invalid({ children }) {
    const { state } = useContext(CdcTokenContext)

    if (state !== 'invalid-token') return null

    return <>{children}</>
}

CdcTokenProvider.TokenStatus = {
    Loading,
    Valid,
    Invalid
}

export const useCdcTokenContext = () => useContext(CdcTokenContext)

const CdcToken = { CdcContext: CdcTokenContext, CdcProvider: CdcTokenProvider, useCdcTokenContext }

export default CdcToken
