import { useSearchParams } from "react-router-dom";
import CdcScreenSet from '../../components/CdcScreenSet';
import ErrorPage from '../ErrorPage';
import './Verification.css'
import { useClientConfig } from '../../providers/ClientConfigProvider'


function VerificationPage() {
  const { clientConfig } = useClientConfig()
  const [searchParams, ] = useSearchParams();
  const errorCode = searchParams.get('errorCode');
  return (
    <div className="content verification">
      {errorCode === '0' ? (
        <CdcScreenSet
          screenSet={ clientConfig?.cdc?.screenSets?.registrationLogin }
          startScreen={ clientConfig?.cdc?.screens['email-verification-with-link'] }
        />
      ) : (
        <ErrorPage errorCode={"8"}/>
      )}
    </div>
  );
}

export default VerificationPage;
