function checkNoTrackingParam(param) {
    const queryParams = new URLSearchParams(window.location.search);

    // Get the 'gig_login_hint' parameter first
    const gigLoginHint = queryParams.get('gig_login_hint');

    if (gigLoginHint && gigLoginHint !== 'undefined') {
        try {
            const parsedLoginHint = JSON.parse(
                decodeURIComponent(gigLoginHint)
            );

            if (param in parsedLoginHint) {
                return convertStringToBoolean(parsedLoginHint[param]);
            }
        } catch (e) {
            console.log('Failed to parse gig_login_hint as JSON:', e);
        }
    }

    // Fallback to check the parameter directly if 'gig_login_hint' is not present
    const directParamValue = queryParams.get(param);
    if (directParamValue !== null && directParamValue !== 'undefined') {
        return convertStringToBoolean(directParamValue);
    }
}

function convertStringToBoolean(value) {
    if (value === 'true') {
        return true;
    } else if (value === 'false') {
        return false;
    }

    // Return the original value if it's not a recognizable boolean string
    return value;
}

export { checkNoTrackingParam };
