import './ProxyPage.css'
import LoadingSpinner from '../../components/LoadingSpinner'
import { useConfig } from '../../providers/ConfigProvider'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'


function ProxyPage() {
    const { config, state: configState } = useConfig()
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        console.log(`Proxy page mode is: ${searchParams.get('mode')}`)
        if (searchParams.get('mode') === 'login') {
            searchParams.set('mode', 'forceLogin')
            setSearchParams(searchParams, { replace: true })
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (configState !== 'available' || !config.cdc.oidcApiKey || !config.cdc.cdnDomain) return

        const scriptId = 'CdcOidc'
        const existingScript = document.getElementById(scriptId)

        if (!existingScript) {
            const script = document.createElement('script')
            script.src = `https://${config.cdc.cdnDomain}/JS/gigya.oidc.js?apiKey=${config.cdc.oidcApiKey}`
            script.id = scriptId
            script.text = JSON.stringify({
                loginURL: 'login',
                consentURL: 'consent', // Part of WebSDK forgetting OIDC Context error workaround - else error
                errorURL: 'error'
            })

            document.getElementById('proxy-container')?.appendChild(script)
            console.log('Loading CDC OIDC script')

            return () => {
                console.log('Unloading CDC OIDC script')
                script.remove()
            }
        }
    }, [configState, config.cdc])

    return (
        <div id="proxy-container" className="content proxy loading-spinner">
            <LoadingSpinner />
        </div>
    )
}

export default ProxyPage
