import { createContext, useContext, useEffect, useState } from 'react'


export const ClientConfigContext = createContext({})

export function ClientConfigProvider({ children, clientKey, returnUrl=null, startScreen=null }) {
    const [ clientConfig, setClientConfig ] = useState({state: 'loading'})
    const cacheKey = 'configCache'

    useEffect(() => {
        async function loadClientConfig(clientKey) {
            let clientConfig
            try {
                clientConfig = await import(`../config/client/${clientKey}`)
            } catch {
                // TODO: Fallunterscheidung einbauen: Gibt es keinen Client Config oder ist das Laden fehlgeschlagen
                setClientConfig({
                    state: 'unavailable'
                })
                return
            }
            setClientConfig({
                // TODO: Default-Config implementieren
                // TODO: Return URL gegen Allow List prüfen
                clientConfig: {
                    ...clientConfig.default,
                    ...(returnUrl ? {returnUrl: returnUrl} : {}),
                    ...(startScreen ? {startScreen: startScreen} : {}),
                },
                clientKey: clientKey,
                state: 'available'
            })
        }

        if (clientKey) {
            loadClientConfig(clientKey)
        } else {
            const cachedConfig = window.sessionStorage.getItem(cacheKey)
            let parsedConfig
            try {
                parsedConfig = JSON.parse(cachedConfig)
            } catch {
                console.log('Failed to parse cached config.')
            }
            if (parsedConfig) {
                setClientConfig({
                    ...parsedConfig,
                    state: 'available'
                })
            } else {
                setClientConfig({
                    state: 'unavailable'
                })
            }
        }
    }, [clientKey, returnUrl, startScreen])

    useEffect(() => {
        window.sessionStorage.setItem(cacheKey, JSON.stringify(clientConfig))
    }, [clientConfig])

    return (
        <ClientConfigContext.Provider value={{ ...clientConfig, setClientConfig }}>
            {children}
        </ClientConfigContext.Provider>
    )
}

function ClientConfigLoading({ children }) {
    const { state } = useClientConfig()

    if (state !== 'loading') return null

    return <>{children}</>
}

function ClientConfigAvailable({ children }) {
    const { state } = useClientConfig()

    if (state !== 'available') return null

    return <>{children}</>
}

function ClientConfigUnavailable({ children }) {
    const { state } = useClientConfig()

    if (state !== 'unavailable') return null

    return <>{children}</>
}

ClientConfigProvider.Loading = ClientConfigLoading
ClientConfigProvider.Available = ClientConfigAvailable
ClientConfigProvider.Unavailable = ClientConfigUnavailable

export function useClientConfig() {
    const context = useContext(ClientConfigContext)

    return context
}

const Config = { ClientConfigContext, ClientConfigProvider, useClientConfig }

export default Config
