import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import './services/i18n'
import { ConfigProvider } from './providers/ConfigProvider'
import { ContentGroupProvider } from './providers/ContentGroupProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
    <ConfigProvider>
        <ContentGroupProvider>
            <App />
        </ContentGroupProvider>
    </ConfigProvider>
)
