
import './LoadingSpinnerMedium.css'
import './LoadingSpinnerBig.css'
import './LoadingSpinnerSmall.css'

function LoadingSpinner() {
    return (
        <div className="loading-spinner">
            <div className="a-cubespinner--medium">
                <div className="a-cubespinner-big"></div>
                <div className="a-cubespinner-middle"></div>
                <div className="a-cubespinner-small"></div>
            </div>
        </div>
    )
}

export default LoadingSpinner
