import { useSearchParams } from "react-router-dom"
import CdcScreenSet from '../../components/CdcScreenSet'
import ErrorPage from '../ErrorPage'
import './SetPasswordPage.css'
import { useClientConfig } from '../../providers/ClientConfigProvider'


function SetPasswordPage() {
    const { clientConfig } = useClientConfig()
    const [searchParams, ] = useSearchParams()
    const pwrt = searchParams.get('pwrt')
    return (
        <div className="content set-password">
            {pwrt ? (
                <CdcScreenSet
                    screenSet={ clientConfig?.cdc?.screenSets?.registrationLogin }
                    startScreen={ clientConfig?.cdc?.screens['password-reset-with-link'] }
                />
            ) : (
                <ErrorPage errorCode={"7"}/>
            )}
        </div>
    )
}

export default SetPasswordPage
