import { useSearchParams } from "react-router-dom";
import CdcScreenSet from '../../components/CdcScreenSet';
import ErrorPage from '../ErrorPage';


function ActivationPage() {
    const [searchParams, ] = useSearchParams();
    const regToken = searchParams.get("regToken");
    const errorCode = searchParams.get("errorCode");

        return (
            <div className="content">
                {regToken && errorCode == 206005 ? (
                    <CdcScreenSet
                        screenSet="MF-Default-RegistrationLogin"
                        startScreen="mf-password-set"
                    />
                ) : (
                    <ErrorPage errorCode={"3"}/>
                )}
            </div>
        );
}

export default ActivationPage;
