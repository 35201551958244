import LoadingSpinner from '../../components/LoadingSpinner'
import { useEffect } from 'react'

function isLocalAndSessionStorageSupported() {
    try {
        const sessionStorage = window.sessionStorage
        sessionStorage.setItem('test', 'test')
        sessionStorage.getItem('test')
        sessionStorage.removeItem('test')
        const localStorage = window.localStorage
        localStorage.setItem('test', 'test')
        localStorage.getItem('test')
        localStorage.removeItem('test')
        return true
    } catch (e) {
        return false
    }
}

function redirectToErrorPage(message = ''){
    window.location.replace('error'.concat(message?`?message=${encodeURI(message)}`:''))
}
/*
 * Part of WebSDK forgetting OIDC Context error workaround - usually this Page is not required / used
 * Approach: Restore localStorage.gigyaCache if possible and redirect back to login page
 * Note: This should only be tried once
*/
function ConsentPage() {
    useEffect(() => {
        if(isLocalAndSessionStorageSupported){
            const oldGigyaCacheValue = window.localStorage.getItem('initialGigyaCache')
            const lastLoginUrl = window.localStorage.getItem('lastLoginUrl')
            const hasAlreadyBeenRetried = window.localStorage.getItem('consentRetried')
            // Check if all necessary inforation exists and if retry has already been attempted
            if(oldGigyaCacheValue && lastLoginUrl && hasAlreadyBeenRetried !== 'true'){
                // restore gigyaCache
                window.sessionStorage.setItem('gigyaCache', oldGigyaCacheValue)
                window.localStorage.setItem('consentRetried', 'true')
                window.location.replace(lastLoginUrl)
            } else if (hasAlreadyBeenRetried === 'true') {
                redirectToErrorPage('oidc context is missing & restoration retry failed')
            } else {
                redirectToErrorPage('oidc context is missing & restoration values are missing')
            }
        } else {
            redirectToErrorPage('oidc context is missing & no access to local- or sessionStorage')
        }
    }, [])
    return (
        <div className="content promptless loading-spinner">
            <LoadingSpinner />
        </div>
    )
}
export default ConsentPage