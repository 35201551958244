import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

var cdcQueryDetector = {
    name: 'cdcQueryString',

    lookup(options) {
        const url = new URL(window.location)
        const uiLocaleLang = url.searchParams.get('gig_ui_locales')
        if(uiLocaleLang) return uiLocaleLang.split(' ')[0]
    },
}

const languageDetector = new LanguageDetector()
languageDetector.addDetector(cdcQueryDetector)

i18n
    .use(Backend)
    .use(languageDetector)
    .use(initReactI18next)
    .init({
        backend: {
                loadPath: '/assets/i18n/{{lng}}.json',
        },
        supportedLngs: [ 
            'de-DE',
            'en-GB',
            'en-US',
            'es-AR',
            'es-MX',
            'fr-FR',
            'it-IT',
            'ja-JP',
            'tr-TR',
            'vi-VN',
            'zh-CN',
            'zh-HK',
            'zh-TW'
        ],
        fallbackLng: 'en-GB',
        debug: false,
        interpolation: {
            escapeValue: false,
            formatSeparator: ',',
        },
        detection: {
            order: ['querystring', 'cdcQueryString', 'cookie', 'localStorage', 'cookie'],
            lookupQuerystring: 'lang',
            caches: ['localStorage', 'cookie'],
        },
        react: {
                wait: true,
        },
    })
    
export default i18n
