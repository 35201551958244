import './Profile.css'
import React from 'react'
import { useEffect } from 'react'
import CdcScreenSet from '../../components/CdcScreenSet'
import LoadingSpinner from '../../components/LoadingSpinner'
import { CdcProvider, useCdcContext } from '../../providers/CdcProvider'
import { useClientConfig } from '../../providers/ClientConfigProvider'
import useExternalScripts from "../../hooks/useExternalScripts"
import { useConfig } from '../../providers/ConfigProvider'

function ProfilePage() {
    const { isCdcSdkReady, dispatch } = useCdcContext()
    const { clientConfig } = useClientConfig()
    const { config, state: configState } = useConfig()
    useExternalScripts({
        src: config?.dataWidget?.src,
        type: 'module',
        id: 'dataWidgetScript',
        defer: true
    }, configState)
    
    useEffect(() => {
        if (isCdcSdkReady === true) {
            window.gigya.accounts.addEventHandlers({
                onLogin: async function (event) {
                    dispatch({
                        type: 'userAvailable',
                        payload: event
                    })
                }
            })
        }
    }, [isCdcSdkReady, dispatch])

    return (
        <React.Fragment>
            <CdcProvider.User.Loading>
                <div className="content profile loading-spinner">
                    <LoadingSpinner />
                </div>
            </CdcProvider.User.Loading>
            <CdcProvider.User.Unavailable>
                <div className="content login">
                    <CdcScreenSet 
                        screenSet={ clientConfig?.cdc?.screenSets?.registrationLogin }
                        startScreen={ clientConfig?.cdc?.screens[clientConfig?.startScreen] }
                    />
                </div>
            </CdcProvider.User.Unavailable>
            <CdcProvider.User.Available>
                <div className="content profile">
                    <CdcScreenSet 
                        screenSet={ clientConfig?.cdc?.screenSets?.profileUpdate }
                        startScreen={ clientConfig?.cdc?.screens['profile'] }
                    />
                </div>
            </CdcProvider.User.Available>
            <CdcProvider.User.PendingRegistration>
                <div className="content profile">
                    <CdcScreenSet 
                        screenSet={ clientConfig?.cdc?.screenSets?.profileUpdate }
                        startScreen={ clientConfig?.cdc?.screens['profile'] }
                    />
                </div>
            </CdcProvider.User.PendingRegistration>
        </React.Fragment>
    )
}

export default ProfilePage
