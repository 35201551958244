import { createContext, useContext, useState } from 'react'
import importedConfig from 'config'


export const ConfigContext = createContext({})

export function ConfigProvider({ children }) {
    const [ config, setConfig ] = useState({config: importedConfig, state: 'available'})

    return (
        <ConfigContext.Provider value={{ ...config, setConfig }}>
            {children}
        </ConfigContext.Provider>
    )
}

function ConfigLoading({ children }) {
    const { state } = useConfig()

    if (state !== 'loading') return null

    return <>{children}</>
}

function ConfigAvailable({ children }) {
    const { state } = useConfig()

    if (state !== 'available') return null

    return <>{children}</>
}

function ConfigUnavailable({ children }) {
    const { state } = useConfig()

    if (state !== 'unavailable') return null

    return <>{children}</>
}

ConfigProvider.Loading = ConfigLoading
ConfigProvider.Available = ConfigAvailable
ConfigProvider.Unavailable = ConfigUnavailable

export function useConfig() {
    const context = useContext(ConfigContext)

    return context
}

const Config = { ConfigContext, ConfigProvider, useConfig }

export default Config
