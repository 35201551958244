import logoFull from './MesseLogoFull.svg'
import logoSimple from './MesseLogoSimple.svg'
import LanguageSelector from '../LanguageSelector'
import './Header.css'

function Header() {
    return (
        <header className="header">
            <div className="main-header">
                <div className="logo-container">
                    <div className="header-spacer"></div>
                    <img src={logoFull} className="logo-full" alt="Messe Frankfurt" />
                    <img src={logoSimple} className="logo-simple" alt="Messe Frankfurt" />
                    <LanguageSelector />
                </div>
            </div>
            <div className="headline">
                <p>Messe-Login</p>
            </div>
        </header>
    )
}

export default Header
