import './App.css';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import LoginPage from './views/LoginPage';
import ProxyPage from './views/ProxyPage';
import ActivationPage from './views/ActivationPage';
import ErrorPage from './views/ErrorPage';
import RegistrationConditions from './views/RegistrationConditionsPage';
import PromptlessPage from './views/PromptlessLoginPage';
import { CdcProvider } from './providers/CdcProvider';
import { ClientConfigProvider } from './providers/ClientConfigProvider';
import { CdcTokenProvider } from './providers/CdcTokenProvider';
import {
    BrowserRouter,
    Route,
    Routes,
    Outlet,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import Redirect from './components/Redirect';
import LoadingSpinner from './components/LoadingSpinner';
import UserCentrics from './components/UserCentrics';
import { ErrorBoundary } from 'react-error-boundary';
import VerificationPage from './views/VerificationPage';
import SetPasswordPage from './views/SetPasswordPage';
import ProfilePage from './views/ProfilePage';
import MobileProfilePage from './views/MobileProfilePage';
import ConsentPage from './views/ConsentPage';

function App() {
    function Clp() {
        function useLoginHint(searchParams) {
            const loginHint = searchParams.get('gig_login_hint');

            if (!loginHint || loginHint === 'undefined') return;

            let parsedLoginHint = {};
            try {
                parsedLoginHint = JSON.parse(loginHint);
            } catch (e) {
                console.log('Login Hint needs to be a valid JSON');
                return;
            }
            return parsedLoginHint;
        }

        const { clientKey: clientKeyPath } = useParams();
        const [searchParams] = useSearchParams();
        const loginHint = useLoginHint(searchParams);

        const clientKey = loginHint?.apikey || clientKeyPath;
        const redirectUrl =
            loginHint?.redirectUrl || searchParams.get('redirectUrl');

        return (
            <ClientConfigProvider
                clientKey={clientKey}
                returnUrl={redirectUrl}
                startScreen={loginHint?.startScreen}
            >
                <ClientConfigProvider.Loading>
                    <LoadingSpinner />
                </ClientConfigProvider.Loading>
                <ClientConfigProvider.Available>
                    <ErrorBoundary fallback="">
                        {<UserCentrics />}
                    </ErrorBoundary>
                    <CdcProvider>
                        <Header />
                        <Outlet />
                        <Footer />
                    </CdcProvider>
                </ClientConfigProvider.Available>
                <ClientConfigProvider.Unavailable>
                    <ErrorBoundary fallback="">
                        {<UserCentrics />}
                    </ErrorBoundary>
                    <Header />
                    <ErrorPage errorCode={'2'} />
                    <Footer />
                </ClientConfigProvider.Unavailable>
            </ClientConfigProvider>
        );
    }

    function TokenPage() {
        function useUrlParams() {
            const [searchParams] = useSearchParams();
            // optional params
            let searchParamApikey = searchParams.get('apikey');
            let searchParamStartScreen = searchParams.get('startScreen');
            // required params
            const searchParamToken = searchParams.get('token');
            const searchParamType = searchParams.get('type');
            const searchParamRedirectUrl = searchParams.get('redirectUrl');

            if (!searchParamApikey || searchParamApikey === 'undefined')
                searchParamApikey = null;
            if (
                !searchParamStartScreen ||
                searchParamStartScreen === 'undefined'
            )
                searchParamStartScreen = null;
            if (!searchParamToken || searchParamToken === 'undefined') return;
            if (!searchParamType || searchParamType === 'undefined') return;
            if (
                !searchParamRedirectUrl ||
                searchParamRedirectUrl === 'undefined'
            )
                return;

            return {
                apikey: searchParamApikey,
                token: searchParamToken,
                type: searchParamType,
                redirectUrl: searchParamRedirectUrl,
                startScreen: searchParamStartScreen,
            };
        }

        const { clientKey: clientKeyPath } = useParams();
        const urlParams = useUrlParams();
        const clientKey = urlParams?.apikey || clientKeyPath;

        return (
            <ClientConfigProvider
                clientKey={clientKey}
                returnUrl={urlParams?.redirectUrl}
                startScreen={urlParams?.startScreen}
            >
                <ClientConfigProvider.Loading>
                    <LoadingSpinner />
                </ClientConfigProvider.Loading>
                <ClientConfigProvider.Available>
                    <ErrorBoundary fallback="">
                        {<UserCentrics />}
                    </ErrorBoundary>
                    <CdcTokenProvider
                        type={urlParams?.type}
                        token={urlParams?.token}
                    >
                        <Header />
                        <Outlet />
                        <Footer />
                    </CdcTokenProvider>
                </ClientConfigProvider.Available>
                <ClientConfigProvider.Unavailable>
                    <ErrorBoundary fallback="">
                        {<UserCentrics />}
                    </ErrorBoundary>
                    <Header />
                    <ErrorPage errorCode={'2'} />
                    <Footer />
                </ClientConfigProvider.Unavailable>
            </ClientConfigProvider>
        );
    }

    return (
        <div className="app">
            <ErrorBoundary fallback={<Redirect to="error" />}>
                <BrowserRouter>
                    <Routes>
                        <Route
                            path="/:clientKey?/proxy"
                            element={<ProxyPage />}
                        />
                        <Route
                            path="/:clientKey?/consent"
                            element={<ConsentPage />}
                        />
                        <Route path="/:clientKey?" element={<Clp />}>
                            <Route path="login" element={<LoginPage />} />
                            <Route
                                path="verification"
                                element={
                                    <React.Fragment>
                                        <VerificationPage />
                                    </React.Fragment>
                                }
                            />
                            <Route
                                path="set-password"
                                element={
                                    <React.Fragment>
                                        <SetPasswordPage />
                                    </React.Fragment>
                                }
                            />
                            <Route
                                path="activation"
                                element={
                                    <React.Fragment>
                                        <ActivationPage />
                                    </React.Fragment>
                                }
                            />
                            <Route
                                path="profile"
                                element={
                                    <React.Fragment>
                                        <ProfilePage />
                                    </React.Fragment>
                                }
                            />
                            <Route
                                path="error"
                                element={<ErrorPage errorCode={'1'} />}
                            />
                        </Route>
                        <Route path="/:clientKey?" element={<TokenPage />}>
                            <Route
                                path="mobile-profile"
                                element={
                                    <React.Fragment>
                                        <MobileProfilePage />
                                    </React.Fragment>
                                }
                            />
                        </Route>
                        <Route
                            path="registration-conditions"
                            element={
                                <React.Fragment>
                                    <ErrorBoundary fallback="">
                                        {<UserCentrics />}
                                    </ErrorBoundary>
                                    <Header />
                                    <RegistrationConditions />
                                    <Footer />
                                </React.Fragment>
                            }
                        />
                        <Route
                            path="promptlessLogin"
                            element={<PromptlessPage />}
                        />
                        <Route
                            path="*"
                            element={<ErrorPage errorCode={'404'} />}
                        />
                    </Routes>
                </BrowserRouter>
            </ErrorBoundary>
        </div>
    );
}

export default App;
