import './CdcScreenSet.css'
import { useEffect } from 'react'
import { useCdcContext } from '../../providers/CdcProvider'
import LoadingSpinner from '../LoadingSpinner'
import { useTranslation } from 'react-i18next'
import { useConfig } from '../../providers/ConfigProvider'
import { useClientConfig } from '../../providers/ClientConfigProvider'

function CdcScreenSet({ screenSet, startScreen, additionalContext = {}, regToken = '', additionalParams = {}, hideProfileUpdateBacklink }) {
    const { isCdcSdkReady } = useCdcContext()
    const { t, i18n } = useTranslation()
    const { config } = useConfig()
    const { clientConfig } = useClientConfig()
    useEffect(() => {
        function showScreenset(screenSet, startScreen = '') {
            
            const returnButtonClass = hideProfileUpdateBacklink
                ? 'hidden-return-button'
                : '';

            const screenSetParams = {
                containerID: 'CdcScreenSet',
                screenSet: screenSet,
                startScreen: startScreen,
                lang: i18n.resolvedLanguage ?? 'en-GB',
                context: {
                    lang: i18n.resolvedLanguage ?? 'en-GB',
                    returnButton: {
                        returnUrl: clientConfig?.returnUrl ? clientConfig.returnUrl : config.app?.defaultReturnUrl,
                        returnText: clientConfig?.returnUrl ? t("Return_Button.Website") : t("Return_Button.Default"),
                        class: returnButtonClass
                    }
                }
            }
            if (regToken) screenSetParams.regToken = regToken
            if (additionalContext) {
                for (const [key, value] of Object.entries(additionalContext)) screenSetParams.context[key] = value
            }

            if (additionalParams) {
                for (const [key, value] of Object.entries(additionalParams)) screenSetParams[key] = value
            }
            
            window.gigya?.accounts?.showScreenSet(screenSetParams)
        }

        if (isCdcSdkReady === true) {
            showScreenset(screenSet, startScreen)
        }
    }, [screenSet, startScreen, additionalContext, regToken, additionalParams, isCdcSdkReady, clientConfig.returnUrl, config.app?.defaultReturnUrl, t, i18n, hideProfileUpdateBacklink])

    return (
        <div id="CdcScreenSet" className="content cdc-screen-set">
            <LoadingSpinner />
        </div>
    )
}

export default CdcScreenSet
