import './Footer.css'
import { useTranslation } from 'react-i18next'
import { ContentGroupContext } from '../../providers/ContentGroupProvider'
import { useContext } from 'react'

function Footer() {
    const { t } = useTranslation()
    const { contentGroups } = useContext(ContentGroupContext)

    return (
        <footer className="footer">
            <div className="link-container">
                <a target="_blank" rel="noreferrer" href={t("Footer.Imprint_link")}>{t("Footer.Imprint_text")}</a>
                <a target="_blank" rel="noreferrer" href={t("Footer.Privacy_Policy_link")}>{t("Footer.Privacy_Policy_text")}</a>
                <a target="_blank" rel="noreferrer" href={`/registration-conditions?appId=${contentGroups[3]}&subId=${contentGroups[4]}&event=${contentGroups[5]}`}>{t("Footer.Registration_Conditions_text")}</a>
            </div>
        </footer>
    )
}

export default Footer
