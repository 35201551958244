module.exports = {
    cdc: {
        oidcApiKey: '4_2h7koY_2LOdpXQFWybT_Bg',
        cdnDomain: 'idp.login.messefrankfurt.com',
        oidcConsentEndpoint: 'https://api.login.messefrankfurt.com/services/clp/consent'
    },
    userCentrics: {
        src: 'https://app.usercentrics.eu/browser-ui/latest/bundle.js',
        id: 'tD38h4HHG'
    },
    app: {
        defaultReturnUrl: 'https://messefrankfurt.com'
    },
    dataWidget: {
        src: 'https://datawidget.messefrankfurt.com/datawidget.js'
    },
    oauth2: {
        getTokenEndpoint: 'https://api.login.messefrankfurt.com/services/clp/get-token'
    },
    mapp: {
        src: 'https://www.messefrankfurt.com/etc/designs/messefrankfurt/vendor/webtrekk_v3_2.js',
        trackId: '412666916963576',
        userCentricsRef: 'Mapp Intelligence Full Feature Tracking'
    }
}
