import { createContext, useCallback, useMemo, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useConfig } from './ConfigProvider'
import { useClientConfig } from './ClientConfigProvider'


export const CdcContext = createContext({})

export const CdcProvider = ({ children, oauthToken = null, skipVerifyLogin = false }) => {
    const { config, state: configState } = useConfig()
    const { clientConfig, state: clientConfigState } = useClientConfig()
    const initialState = {
        apiKey: null,
        isCdcSdkReady: false,
        user: null,
        state: null
    }

    function reducer(state, action) {
        switch (action.type) {
            case 'cdcInitialized': {
                return {
                    ...state,
                    apiKey: action.payload,
                    isCdcSdkReady: true
                }
            }
            case 'cdcUnloaded': {
                return initialState
            }
            case 'userLoading': {
                return {
                    ...state,
                    state: 'loading'
                }
            }
            case 'userAvailable': {
                return {
                    ...state,
                    state: 'done',
                    user: action.payload,
                    regToken: action.payload?.regToken
                }
            }
            case 'userUnavailable': {
                return {
                    ...state,
                    state: 'unavailable'
                }
            }
            case 'userPendingRegistration': {
                return {
                    ...state,
                    state: 'pending-registration',
                    user: action.payload,
                    regToken: action.payload?.regToken
                }
            }
            default:
                throw Error(`Unknown action: ${action.type}`)
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    const { i18n } = useTranslation()

    const handleLoginResponse = useCallback(
        (response) => {
            if (response.errorCode === 0) {
                i18n.changeLanguage(response.profile.locale);

                dispatch({
                    type: 'userAvailable',
                    payload: response,
                })
            } else if (response.errorCode === 206001) {
                dispatch({
                    type: 'userPendingRegistration',
                    payload: response,
                })
            } else {
                dispatch({
                    type: 'userUnavailable'
                })
            }

        },
        [dispatch]
    )

    const context = useMemo(() => ({ ...state, dispatch, handleLoginResponse}), [state, handleLoginResponse])

    useEffect(() => {
        window.__gigyaConf = {
            ignoreInterruptions: true,
            include: 'missing-required-fields'
        }

        window.onGigyaServiceReady = function () {
            console.log('CDC initialized!')
            dispatch({ type: 'cdcInitialized', payload: clientConfig?.cdc?.apiKey })
            dispatch({ type: 'userLoading' })
            if (window?.localStorage?.getItem('sdkDebugMode')) window.gigya.logger.enable()
            if (oauthToken && skipVerifyLogin) {
                // Note: No userdata available - it's possible to do verify & token but it's slower and redundant
                dispatch({ type: 'userAvailable', payload: {} });
            } else {
                try {
                    window.gigya.accounts.verifyLogin({
                        include: 'preference, profile, data',
                        callback: (loginResponse) => {
                            if (loginResponse.errorCode === 0) {
                                window.gigya.accounts.getAccountInfo({
                                    include: 'preference, profile, data',
                                    extraProfileFields: 'locale',
                                    callback: (accountInfoResponse) => {
                                        if (
                                            accountInfoResponse.errorCode === 0
                                        ) {
                                            handleLoginResponse(
                                                accountInfoResponse
                                            );
                                        } else {
                                            console.error(
                                                'Failed to retrieve account info:',
                                                accountInfoResponse.errorMessage
                                            );
                                        }
                                    },
                                });
                            } else {
                                window.gigya.accounts.verifyLogin({
                                    include: 'preference, profile, data',
                                    callback: handleLoginResponse,
                                });
                            }
                        },
                    });
                } catch (error) {
                    console.error(
                        'Unexpected error during login verification or account information retrieval:',
                        error
                    );
                }
            }
        };
    }, [handleLoginResponse, clientConfig?.cdc?.apiKey, oauthToken,skipVerifyLogin])

    useEffect(() => {
        if (configState !== 'available' || !config?.cdc?.cdnDomain) return
        if (clientConfigState !== 'available' || !clientConfig?.cdc?.apiKey) return

        const scriptId = 'CdcWebSdk'
        const existingScript = document.getElementById(scriptId)

        if (!existingScript && !window.gigya) {
            const script = document.createElement('script')
            script.src = `https://${config.cdc.cdnDomain}/JS/gigya.js?apiKey=${clientConfig.cdc.apiKey}&lang=${i18n.resolvedLanguage}`
            script.id = scriptId
            if (oauthToken) script.text = '{oauth_token: "' + oauthToken + '"}'

            document.body.appendChild(script)
            console.log('Loading CDC WebSDK')

            return () => {
                console.log('Unloading CDC WebSDK')
                script.remove()
                if (window.gigya !== 'undefined') window.gigya = undefined
                dispatch({type: 'cdcUnloaded'})
            }
        }
    }, [configState, config?.cdc, clientConfigState, clientConfig?.cdc, i18n.resolvedLanguage, oauthToken])

    return (
        <CdcContext.Provider value={context}>
            {children}
        </CdcContext.Provider>
    )
}

function Available({ children }) {
    const { state, user } = useContext(CdcContext)

    if (state !== 'done' || !user) return null

    return <>{children}</>
}

function Unavailable({ children }) {
    const { state } = useContext(CdcContext)

    if (state !== 'unavailable') return null

    return <>{children}</>
}

function Loading({ children }) {
    const { state } = useContext(CdcContext)

    if (state !== 'loading') return null

    return <>{children}</>
}

function PendingRegistration({ children }) {
    const { state } = useContext(CdcContext)

    if (state !== 'pending-registration') return null

    return <>{children}</>
}

CdcProvider.User = {
    Available,
    Unavailable,
    Loading,
    PendingRegistration
}

export const useCdcContext = () => useContext(CdcContext)

const Cdc = { CdcContext, CdcProvider, useCdcContext }

export default Cdc
