import LoadingSpinner from '../../components/LoadingSpinner'
import { useEffect } from 'react'

function PromptlessPage() {
    useEffect(() => {
        if(window.location){
            const queryString = window.location.search
            if(queryString.length === 0){ // promptless login worked
                window.parent.postMessage({ success: true, time: Date.now().toString() }, '*')
            } else { // promptless login failed
                const urlParams = new URLSearchParams(queryString)
                window.parent.postMessage({ success: false, errorMessage: urlParams.get('errorMessage'), errorCode: urlParams.get('errorCode'), time: Date.now().toString() }, '*')
            }
        }
    }, [])

    return (
        <div className="content promptless loading-spinner">
            <LoadingSpinner />
        </div>
    )
}

export default PromptlessPage