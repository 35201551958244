import CdcScreenSet from '../../components/CdcScreenSet'
import LoadingSpinner from '../../components/LoadingSpinner'
import ErrorPage from '../ErrorPage'
import './MobileProfile.css'
import { useClientConfig } from '../../providers/ClientConfigProvider'
import { CdcTokenProvider, useCdcTokenContext } from '../../providers/CdcTokenProvider'
import { CdcProvider } from '../../providers/CdcProvider'
import React from 'react'
import useExternalScripts from "../../hooks/useExternalScripts"
import { useConfig } from '../../providers/ConfigProvider'


function MobileProfilePage() {
    const { clientConfig } = useClientConfig()
    const { token } = useCdcTokenContext()
    const { config, state: configState } = useConfig()
    useExternalScripts({
        src: config?.dataWidget?.src,
        type: 'module',
        id: 'dataWidgetScript',
        defer: true
    }, configState)

    return (
        <React.Fragment>
            <CdcTokenProvider.TokenStatus.Loading>
                <div className="content profile loading-spinner">
                    <LoadingSpinner />
                </div>
            </CdcTokenProvider.TokenStatus.Loading>
            <CdcTokenProvider.TokenStatus.Valid>
                <CdcProvider oauthToken={token} skipVerifyLogin={true}>
                    <React.Fragment>
                        <CdcProvider.User.Loading>
                            <div className="content profile loading-spinner">
                                <LoadingSpinner />
                            </div>
                        </CdcProvider.User.Loading>
                        <CdcProvider.User.Unavailable>
                            <ErrorPage errorCode={"4"}/>
                        </CdcProvider.User.Unavailable>
                        <CdcProvider.User.Available>
                            <div className="content profile">
                                <CdcScreenSet
                                    screenSet={
                                        clientConfig?.cdc?.screenSets
                                            ?.profileUpdate
                                    }
                                    startScreen={
                                        clientConfig?.cdc?.screens[
                                            'profile-mobile'
                                        ]
                                    }
                                    hideProfileUpdateBacklink={true}
                                />
                            </div>
                        </CdcProvider.User.Available>
                        <CdcProvider.User.PendingRegistration>
                            <ErrorPage errorCode={"5"}/>
                        </CdcProvider.User.PendingRegistration>
                    </React.Fragment>
                </CdcProvider>
            </CdcTokenProvider.TokenStatus.Valid>
            <CdcTokenProvider.TokenStatus.Invalid>
                <ErrorPage errorCode={"6"}/>
            </CdcTokenProvider.TokenStatus.Invalid>
        </React.Fragment>
    )
}

export default MobileProfilePage
