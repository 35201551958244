import { useEffect } from 'react'

export default function useExternalScripts(properties = {}, configState){
    useEffect(() => {
        if(configState !== 'available') return

        const head = document.querySelector("head")
        const script = document.createElement("script")

        for(const [key, value] of Object.entries(properties)) script.setAttribute(key, value)
        
        if(script.src && script.id){
                if(!document.getElementById(script.id)) head.appendChild(script)
        }
        
        return () => {}
    }, [properties, configState])
};