import './LanguageSelector.css'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { useCdcContext } from '../../providers/CdcProvider';

function LanguageSelector() {
    const { i18n } = useTranslation()
    const { user } = useCdcContext();

    const countryMapping = (countryCode) => {
      const countries = {
        'de-DE' : 'DE (DE)',
        'en-GB' : 'EN (GB)',
        'en-US' : 'EN (US)',
        'es-AR' : 'ES (AR)',
        'es-MX' : 'ES (MX)',
        'fr-FR' : 'FR (FR)',
        'it-IT' : 'IT (IT)',
        'ja-JP' : 'JA (JP)',
        'tr-TR' : 'TR (TR)',
        'vi-VN' : 'VI (VN)',
        'zh-CN' : 'ZH (CN)',
        'zh-HK' : 'ZH (HK)',
        'zh-TW' : 'ZH (TW)'
      }
      return countries[countryCode] ?? 'EN (GB)'
    }
    
    const handleLanguageIconClick = (e) => {
      if(window.visualViewport.width <= 584){
        // currently not working because browsers don't support JS onClick events for select elements
        document.querySelector('.header .language-container .language-selector').click()
      } else {
        document.querySelector('.header .language-container .clp-custom-select').click()
      }
      e.preventDefault()
    }

    const [searchParams, setSearchParams] = useSearchParams()

    function clearLanguageQueryStrings() {
      const paramsToRemove = ['lang', 'gig_ui_locales']

      let paramsModified = false
      paramsToRemove.forEach(param => {
        if (searchParams.has(param)) {
          searchParams.delete(param)
          paramsModified = true
        }
      })

      if (paramsModified) {
        setSearchParams(searchParams)
      }
    }

    const handleLanguageChange = async (e) => {
        const selectedLang =
            e.type === 'change'
                ? e.target.value
                : e.target.getAttribute('value');
        const currentLang = i18n.resolvedLanguage;

        // Check if the language change is necessary
        if (selectedLang !== currentLang) {
            try {
                if (user) {
                    // Await the completion of the account info update
                    await new Promise((resolve, reject) => {
                        window.gigya.accounts.setAccountInfo({
                            profile: { locale: selectedLang },
                            callback: (res) => {
                                if (res.errorCode === 0) {
                                    resolve(res);
                                } else {
                                    reject(res);
                                }
                            },
                        });
                    });
                }

                // If successful, clear the query strings and change language
                clearLanguageQueryStrings();
                i18n.changeLanguage(selectedLang);

                // Now it's safe to reload the page
                window.location.reload(false);
            } catch (error) {
                console.error('Failed to update language:', error);
            }
        }
    };

    const toggleCustomSelect = (e) => {
      e.stopPropagation()
      const dropdownMenu = e.target.nextSibling

      // expand or contract dropdown
      dropdownMenu.classList.toggle("clp-custom-dropdown-hidden")
      e.target.classList.toggle("clp-select-active")

      // highlight selected language
      dropdownMenu.childNodes.forEach(languageOption => {
        if (languageOption.innerHTML === e.target.innerHTML){
          languageOption.classList.add('clp-same-as-selected')
        } else {
          languageOption.classList.remove('clp-same-as-selected')
        }
      })

      if(!e.target.value)
      // Prescroll to the already selected element
      if(e.target.nextSibling.children.length > 4){
        for(var option of dropdownMenu.children){
          if(option.innerHTML === e.target.innerHTML){
              // Calculate the scroll position of the selected option
              const optionOffsetTop = option.offsetTop
              const containerHeight = option.offsetHeight
              const optionHeight = option.offsetHeight
              const scrollPosition = optionOffsetTop - (containerHeight / 2) + (optionHeight / 2)
              // scroll to position
              dropdownMenu.scrollTop = scrollPosition
              break
            }
          }
      }
    }
    
    function closeAllSelects(event) {
      if(event.target.classList.contains('language-container')) return
      const customSelects = document.querySelectorAll('.clp-custom-select')
      customSelects.forEach(customSelect => {
        if (customSelect.classList.contains('clp-select-active')) {
          const activeDropdown = customSelect.parentNode.querySelector('.clp-custom-dropdown')
          customSelect.classList.remove('clp-select-active')
          activeDropdown.classList.add('clp-custom-dropdown-hidden')
        }
      })
    }

    document.addEventListener("click", closeAllSelects)

    return (
        <div className="header language">
            <div className="language-container" onClick={handleLanguageIconClick}>
              <select className='header language-selector' value={i18n.resolvedLanguage} onChange={handleLanguageChange}>
                  <option value="de-DE">DE (DE)</option>
                  <option value="en-GB">EN (GB)</option>
                  <option value="en-US">EN (US)</option>
                  <option value="es-AR">ES (AR)</option>
                  <option value="es-MX">ES (MX)</option>
                  <option value="fr-FR">FR (FR)</option>
                  <option value="it-IT">IT (IT)</option>
                  <option value="ja-JP">JA (JP)</option>
                  <option value="tr-TR">TR (TR)</option>
                  <option value="vi-VN">VI (VN)</option>
                  <option value="zh-CN">ZH (CN)</option>
                  <option value="zh-HK">ZH (HK)</option>
                  <option value="zh-TW">ZH (TW)</option>
              </select>
              <div className="clp-custom-select" onClick={toggleCustomSelect}>{countryMapping(i18n.resolvedLanguage)}</div>
              <div className="clp-custom-dropdown clp-custom-dropdown-hidden">
                  <div value="de-DE" onClick={handleLanguageChange}>DE (DE)</div>
                  <div value="en-GB" onClick={handleLanguageChange}>EN (GB)</div>
                  <div value="en-US" onClick={handleLanguageChange}>EN (US)</div>
                  <div value="es-AR" onClick={handleLanguageChange}>ES (AR)</div>
                  <div value="es-MX" onClick={handleLanguageChange}>ES (MX)</div>
                  <div value="fr-FR" onClick={handleLanguageChange}>FR (FR)</div>
                  <div value="it-IT" onClick={handleLanguageChange}>IT (IT)</div>
                  <div value="ja-JP" onClick={handleLanguageChange}>JA (JP)</div>
                  <div value="tr-TR" onClick={handleLanguageChange}>TR (TR)</div>
                  <div value="vi-VN" onClick={handleLanguageChange}>VI (VN)</div>
                  <div value="zh-CN" onClick={handleLanguageChange}>ZH (CN)</div>
                  <div value="zh-HK" onClick={handleLanguageChange}>ZH (HK)</div>
                  <div value="zh-TW" onClick={handleLanguageChange}>ZH (TW)</div>
              </div>
            </div>
        </div>
    )
}

export default LanguageSelector
