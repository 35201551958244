import './RegistrationConditions.css'
import { useTranslation } from 'react-i18next'
import { ContentGroupContext } from '../../providers/ContentGroupProvider'
import { useEffect, useContext } from 'react'

function RegistrationConditions() {
    const { t } = useTranslation()
    const { isMappScriptReady } = useContext(ContentGroupContext)

    useEffect(() => {
        if(isMappScriptReady){
            window.dispatchEvent(new CustomEvent('cdc-screen-loaded', {
                detail: {
                    cg6: 'footer',
                    cg7: 'terms_conditions',
                }
            }))
        }
    }, [isMappScriptReady])

    return (
        <div className="content registration-conditions">
            <div className="registration-conditions-body">
                <h1>{t('Registration_Conditions.Header')}</h1>
                <h2>{t('Registration_Conditions.Paragraph-A-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-A-Text')}</p>
                <h2>{t('Registration_Conditions.Paragraph-1-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-1-Text')}</p>
                <h2>{t('Registration_Conditions.Paragraph-2-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-2-Text')}</p>
                <h2>{t('Registration_Conditions.Paragraph-3-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-3-Text')}</p>
                <h2>{t('Registration_Conditions.Paragraph-4-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-4-Text')}</p>
                <h2>{t('Registration_Conditions.Paragraph-5-Header')}</h2>
                <p>{t('Registration_Conditions.Paragraph-5-Text')}</p>
            </div>
        </div>
    )
}

export default RegistrationConditions
