// Creates the script element that loads the mapp javascript and adds it to the <head>
const loadMappScript = (config, setIsMappScriptReady) => {
    const scriptId = 'mapp-script'
    let mappScript = document.getElementById(scriptId)

    // Check if the Mapp script already exists in the document
    if (!mappScript) {
        mappScript = document.createElement('script')
        mappScript.id = scriptId
        mappScript.src = config?.mapp?.src
        mappScript.type = 'text/javascript'

        mappScript.onload = () => {
            setIsMappScriptReady(true)
            if (window?.localStorage?.getItem('debugMode')) console.log('Mapp script loaded successfully')
        }
        mappScript.onerror = () => {
            if (window?.localStorage?.getItem('debugMode')) console.error('Failed to load the Mapp script')
        }
        document.head.appendChild(mappScript)
    }
}

/* 
 * Function adds a script to the head that is not executed until usercentrics activates it
 * Script adds static mapp config, adds the event listener to forward tracking events to mapp, deactivates the localTracking and forwards locally tracked events
 */
const addMappConfigWithEventListener = (config) => {
    const usercentricsEnabledScript = document.createElement('script')
    usercentricsEnabledScript.id = 'webtrekk_webtrekkConfig'
    usercentricsEnabledScript.type = 'text/plain'
    usercentricsEnabledScript.setAttribute('data-usercentrics', config?.mapp?.userCentricsRef)
    usercentricsEnabledScript.textContent = `var webtrekkConfig = {
        trackId : "${config?.mapp?.trackId}",
        trackDomain : "messefrankfurt01.webtrekk.net",
        domain : "REGEXP:(messefrankfurt\\\\.com)",
        cookie : "1",
        mediaCode : "wt_mc",
        executePluginFunction: "wt_scrollposition;wt_frequencyAnalysis"
    };
    window.addEventListener('cdc-screen-loaded', window.mappEventListenerCallback);
    if(window?.localTracking?.active){
        window.removeEventListener('cdc-screen-loaded', window.localTracking.eventHandler);
        window.localTracking.queue.forEach(eventDetail => window.dispatchEvent(new CustomEvent('cdc-screen-loaded', { detail: eventDetail })))
        window.localTracking.queue = []
        window.localTracking.active = false
    }`
    document.head.appendChild(usercentricsEnabledScript)
}

// Page will only do local tracking until usercentrics consent is given, then the accumulated events will be processed
const addLocalTracking = () => {
    if(!window.localTracking){
        window.localTracking = {
            queue: [],
            eventHandler: (event) => {
                window.localTracking.queue.push(event.detail)
            },
            active: true
        }
        window.addEventListener('cdc-screen-loaded', window.localTracking.eventHandler)
    }
}

// Callback function that will enrich tracking events and forward them to Mapp
const createMappEventListenerCallback = (contentGroups) => {
    window.mappEventListenerCallback = (event) => {
        if(!event?.detail?.cg6 || !event?.detail?.cg7){
            if(window?.localStorage?.getItem('debugMode')) console.log('Event not forwarded to Mapp due to missing cg6 or cg7')
            return
        }
        if(!window.webtrekkV3){
            if(window?.localStorage?.getItem('debugMode')) console.log('Webtrekk not found in window.webtrekkV3')
            return
        }

        const contentGroup = {
            1 : contentGroups[1], // Messe-Login (fix)
            2 : contentGroups[2], // Sprachauswahl nach Format (Sprache–Land)
            3 : contentGroups[3], // Trägerapplikation als App-ID
            4 : contentGroups[4], // Tochtergesellschaft als Subsidiary-ID
            5 : contentGroups[5], // Veranstaltung als EventNumber
            6 : event.detail['cg6'], // 1. Hauptnavigation (messe_login, manage_your_info)
            7 : event.detail['cg7'] // 1. Subnavigation
        }

        var pageConfig = {
            linkTrack : "link",
            heatmap : "0",
            form : "1",
            contentId : Object.values(contentGroup).join('.')
        }
        
        var wt = new window.webtrekkV3(pageConfig)
        wt.linkTrackDownloads = "pdf;doc;ppt;xls;csv;ics;jpg"
        wt.contentGroup = contentGroup
        if(event.detail['uid']) wt.customerId = event.detail['uid']
        wt.sendinfo()
    }
}

const initializeMapp = (contentGroups, config, setIsMappScriptReady) => {
    addLocalTracking()
    loadMappScript(config, setIsMappScriptReady)
    createMappEventListenerCallback(contentGroups)
    addMappConfigWithEventListener(config)
}

export { initializeMapp }
